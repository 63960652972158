export const COMPANY = 'lukoil';
export const DOMAIN = 'lukoil-kadrovyj-rezerv.experium.ru';

export const VACANCY_CLASSIFIERS = {
    center: 'ООО «ЛУКОЙЛ-Центрнефтепродукт»',
    south: 'ООО «ЛУКОЙЛ-Югнефтепродукт»',
    east: 'ООО «ЛУКОЙЛ-Северо-Западнефтепродукт»',
    ural: 'ООО «ЛУКОЙЛ-Уралнефтепродукт»',
};

export const VACANCY_REGIONS = {
    'г. Москва': 'center',
    'Московская область': 'center',
    'Тверская область': 'center',
    'Смоленская область': 'center',
    'Нижегородская область': 'center',
    'Владимирская область': 'center',
    'Вологодская область': 'center',
    'Республика Марий Эл': 'center',
    'Республика Мордовия': 'center',
    'Ярославская область': 'center',
    // 'Республика Татарстан': 'center',
    'Чувашская республика': 'center',
    // '': 'center',
    'Краснодарский край': 'south',
    'Ставропольский край': 'south',
    'Республика Адыгея': 'south',
    'Ростовская область': 'south',
    'Кабардино-Балкарская республика': 'south',
    'Карачаево-Черкесская республика': 'south',
    'Республика Северная Осетия-Алания': 'south',
    'Республика Калмыкия': 'south',
    'Волгоградская область': 'south',
    'Саратовская область': 'south',
    'Пензенская область': 'south',
    'Астраханская область': 'south',
    'Тульская область': 'south',
    'Липецкая область': 'south',
    'Белгородская область': 'south',
    'Орловская область': 'south',
    'Курская область': 'south',
    'Тамбовская область': 'south',
    'Рязанская область': 'south',
    'Брянская область': 'south',
    'Калужская область': 'south',
    'Воронежская область': 'south',
    // '': 'south',
    'г. Санкт-Петербург': 'east',
    'Ленинградская область': 'east',
    'Мурманская область': 'east',
    'Архангельская область': 'east',
    'Новгородская область': 'east',
    'Псковская область': 'east',
    'Калининградская область': 'east',
    'Республика Коми': 'east',
    'Республика Карелия': 'east',
    'Ненецкий автономный округ': 'east',
    'Ямало-Ненецкий автономный округ': 'east',
    // '': 'east',
    'Республика Башкортостан': 'ural',
    'Республика Татарстан': 'ural',
    'Удмуртская республика': 'ural',
    'Оренбургская область': 'ural',
    'Самарская область': 'ural',
    'Тюменская область': 'ural',
    'Челябинская область': 'ural',
    'Свердловская область': 'ural',
    'Кировская область': 'ural',
    'Курганская область': 'ural',
    'Ханты-Мансийский автономный округ - Югра': 'ural',
    'Пермский край': 'ural',
    // '': 'ural',
};
