import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useQuery, useMutation } from 'react-query';
import { path } from 'ramda';
import axios from 'axios';

import Vacancy from './Vacancy';
import { COMPANY, DOMAIN } from '../constants/domain';
import { getUtmFromSearch, getUtmTool } from '../utils/utm';

const VacancyList = ({ vacancySubmit, history }) => {
    const [modal, setModal] = useState(vacancySubmit || null);

    const mutation = useMutation(({ vacancy, domain, values }) => {
        const url = vacancy ? `vacancy/${vacancy}/${domain}` : `domain/${domain}`;

        return axios.post(`/api/public/${url}/applicant`, {
            ...values,
            _utm: getUtmFromSearch(),
            _tool: getUtmTool(),
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': 'ru',
            }
        })
    });
    const { isLoading, error, data } = useQuery("vacancies", () =>
        axios.get(`/api/public/vacancy`, {
            params: {
                filter: {
                    companyCode: COMPANY,
                    domainName: DOMAIN,
                },
                relations: ['cities', 'classifiers', 'domains', 'domains.company', 'domains.vacancyFormPreset', 'domains.domainFormPreset']
            }
        }).then((res) => res.data)
    );

    const vacancies = (path(['items'], data) || []);
    const domainForm = path(['items', 0, '_relations', 'domains', 0], data) || {};

    useEffect(() => {
        if (vacancySubmit && !((vacancySubmit === 'submit') || (vacancySubmit === 'submit-success'))) {
            setModal(null);
            history.replace('/');
        } else {
            setModal(vacancySubmit);
        }
    // eslint-disable-next-line
    }, [vacancySubmit]);

    useEffect(() => {
        if (mutation.isSuccess) {
            setModal(`${modal}-success`);
            history.push(`/${modal}-success`);
            mutation.reset();
        }
    }, [mutation.isSuccess, modal, mutation, history]);

    const closeModal = () => {
        history.push(`/`);
        setModal(null);
    };

    return (
        <div className="vacancy-list">
            <Modal isOpen={!!modal} onRequestClose={closeModal} ariaHideApp={false}>
                <div className="modal-header">
                    <h3>Вакансия</h3>
                    <button className="btn-close" onClick={closeModal}>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24 8L8 24" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8 8L24 24" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                </div>
                <div className="modal-body">
                    { vacancySubmit === 'submit' ? (
                        error || (!isLoading && !vacancies.length) ? (
                            <p style={{ 'textAlign': 'center' }}>
                                {error ? (
                                    'Не удалось загрузить вакансию, попробуйте обновить страницу'
                                ) : (
                                    'Нет доступных вакансий'
                                )}
                            </p>
                        ) : (
                            isLoading ? 'Загрузка вакансии...' : (
                                <Vacancy
                                    mutation={mutation}
                                    vacancy={vacancies[0]}
                                    domain={domainForm}
                                />
                            )
                        )
                    ) : (
                        <h2>ВАША АНКЕТА НАПРАВЛЕНА В ОТДЕЛ ПО РАБОТЕ С ПЕРСОНАЛОМ</h2>
                    )}
                </div>
            </Modal>
        </div>
    );
}

export default VacancyList;
