import React, { Component } from 'react';
import { path } from 'ramda';

import VacancyList from "./VacancyList";

export default class Main extends Component {
    render() {
        return <div>
            <VacancyList {...this.props} vacancySubmit={path(['match', 'params', 'vacancy'], this.props)} />
        </div>;
    }
}
