import React, { Component } from 'react';
import { assocPath, compose, path, pathOr } from 'ramda';
import Form from '@experium/findy-form';

import '@experium/findy-form/lib/styles/index.css';

const QUESTION_START = 17;
const QUESTION_LINKED_1 = QUESTION_START;
const QUESTION_1_1 = QUESTION_START + 1;
const QUESTION_1_2 = QUESTION_START + 2;

const QUESTION_LINKED_2 = QUESTION_START + 3;
const QUESTION_2_1 = QUESTION_START + 4;
const QUESTION_2_2 = QUESTION_START + 5;
const QUESTION_2_3 = QUESTION_START + 9;

const QUESTION_LINKED_2_1 = QUESTION_START + 5;
const QUESTION_2_1_1 = QUESTION_START + 6;
const QUESTION_2_1_2 = QUESTION_START + 7;
const QUESTION_2_1_3 = QUESTION_START + 8;

const QUESTION_LINKED_2_2 = QUESTION_START + 9;
const QUESTION_2_2_1 = QUESTION_START + 10;


const VacancyForm = ({ domain, mutation, onSubmit }) => {
    const preset = path(['_relations', 'vacancyFormPreset'], domain);

    const fields = compose(
        // 10
        // 10.1
        assocPath(['questions', QUESTION_1_1, 'settings', 'linkField'], path(['questions', QUESTION_LINKED_1, 'field'], preset)),
        assocPath(['questions', QUESTION_1_1, 'settings', 'linkType'], 'hide'),
        assocPath(['questions', QUESTION_1_1, 'settings', 'linkValue'], path(['questions', QUESTION_LINKED_1, 'settings', 'choices', 1, 'id'], preset)),
        assocPath(['questions', QUESTION_1_1, 'required'], true),
        // 10.2
        assocPath(['questions', QUESTION_1_2, 'settings', 'linkField'], path(['questions', QUESTION_LINKED_1, 'field'], preset)),
        assocPath(['questions', QUESTION_1_2, 'settings', 'linkType'], 'hide'),
        assocPath(['questions', QUESTION_1_2, 'settings', 'linkValue'], path(['questions', QUESTION_LINKED_1, 'settings', 'choices', 2, 'id'], preset)),
        assocPath(['questions', QUESTION_1_2, 'required'], true),
        // 11
        // 11.1
        assocPath(['questions', QUESTION_2_1, 'settings', 'linkField'], path(['questions', QUESTION_LINKED_2, 'field'], preset)),
        assocPath(['questions', QUESTION_2_1, 'settings', 'linkType'], 'hide'),
        assocPath(['questions', QUESTION_2_1, 'settings', 'linkValue'], path(['questions', QUESTION_LINKED_2, 'settings', 'choices', 1, 'id'], preset)),
        assocPath(['questions', QUESTION_2_1, 'required'], true),
        // 11.2
        // 11.2.1
        assocPath(['questions', QUESTION_2_2, 'settings', 'linkField'], path(['questions', QUESTION_LINKED_2, 'field'], preset)),
        assocPath(['questions', QUESTION_2_2, 'settings', 'linkType'], 'hide'),
        assocPath(['questions', QUESTION_2_2, 'settings', 'linkValue'], path(['questions', QUESTION_LINKED_2, 'settings', 'choices', 0, 'id'], preset)),
        // 11.2.1.
        assocPath(['questions', QUESTION_2_1_1, 'settings', 'linkField'], path(['questions', QUESTION_LINKED_2_1, 'field'], preset)),
        assocPath(['questions', QUESTION_2_1_1, 'settings', 'linkType'], 'hide'),
        assocPath(['questions', QUESTION_2_1_1, 'settings', 'linkValue'], true),
        assocPath(['questions', QUESTION_2_1_1, 'required'], true),
            assocPath(['questions', QUESTION_2_1_2, 'settings', 'linkField'], path(['questions', QUESTION_2_1_1, 'field'], preset)),
            assocPath(['questions', QUESTION_2_1_2, 'settings', 'linkType'], 'hide'),
            assocPath(['questions', QUESTION_2_1_2, 'settings', 'linkValue'], path(['questions', QUESTION_2_1_1, 'settings', 'choices', 2, 'id'], preset)),
            assocPath(['questions', QUESTION_2_1_2, 'required'], true),
        assocPath(['questions', QUESTION_2_1_3, 'settings', 'linkField'], path(['questions', QUESTION_LINKED_2_1, 'field'], preset)),
        assocPath(['questions', QUESTION_2_1_3, 'settings', 'linkType'], 'hide'),
        assocPath(['questions', QUESTION_2_1_3, 'settings', 'linkValue'], true),
        assocPath(['questions', QUESTION_2_1_3, 'required'], true),
        // 11.2.2
        assocPath(['questions', QUESTION_2_3, 'settings', 'linkField'], path(['questions', QUESTION_LINKED_2, 'field'], preset)),
        assocPath(['questions', QUESTION_2_3, 'settings', 'linkType'], 'hide'),
        assocPath(['questions', QUESTION_2_3, 'settings', 'linkValue'], path(['questions', QUESTION_LINKED_2, 'settings', 'choices', 0, 'id'], preset)),
        // 11.2.2.
        assocPath(['questions', QUESTION_2_2_1, 'settings', 'linkField'], path(['questions', QUESTION_LINKED_2_2, 'field'], preset)),
        assocPath(['questions', QUESTION_2_2_1, 'settings', 'linkType'], 'hide'),
        assocPath(['questions', QUESTION_2_2_1, 'settings', 'linkValue'], true),
        assocPath(['questions', QUESTION_2_2_1, 'required'], true),
    )(preset);

    return <Form
        fields={path(['questions'], fields)}
        onSubmit={onSubmit}
        opd={pathOr('', ['_relations', 'company', 'pdaSettings', 'text'], domain)}
        htmlOpdStyles={'.opd-html-form div > div { text-align: justify; }'}
        opdSettings={pathOr({}, ['_relations', 'company', 'pdaSettings'], domain)}
        allowFileExtensions={path(['_relations', 'company', 'companySettings', 'allowFileExtensions'], domain)}
        captcha={path(['_relations', 'company', 'companySettings', 'captcha', 'domains'], domain)}
        captchaOptions={path(['_relations', 'domainFormPreset', 'options'], domain)}
        company={pathOr({}, ['_relations', 'company', 'id'], domain)}
        postFileUrl={`/api/public/file/${domain.id}`}
        getFileUrl={id => `${window.location.origin}/api/file/${id}`}
        language='ru'
        languageOrigin='ru'
        pdaLanguage='ru'
        serverErrors={path(['response', 'data', 'errors'], mutation.error)}
        submitting={mutation.isLoading}
        scrollContainerClassName={'.modal-body'}
    />;
}

export default class Vacancy extends Component {
    onSubmit = (values, formProps) => {
        const domain = this.props.domain.id;

        const id = path(['id'], this.props.vacancy);

        if (id) {
            return this.props.mutation.mutate({
                vacancy: id,
                domain,
                values,
            });
        }

        this.props.mutation.mutate({ vacancy: null, domain, values });
    }

    render() {
        return (
            <div className="wrap-form">
                <VacancyForm {...this.props} onSubmit={this.onSubmit} />
            </div>
        );
    }
}
