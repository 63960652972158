import React from 'react';
import { Link } from "react-router-dom";
import 'react-dropdown/style.css';

import head from '../assets/img/head.webp';
import logo from '../assets/img/logo.svg';

const Header = () => {

  return (
      <div className="header">
          <div className="row">
              <div className="col-1">
                  <div className="content">
                      <a href="/"><img className="logo" src={logo} alt=""/></a>
                      <h1>Формирование резерва кадров организаций бизнес-сектора &laquo;Нефте&shy;продукто&shy;обеспечение&raquo; в&nbsp;РФ</h1>
                      <Link to="/submit" className="btn btn-outline btn-white">Заполнить анкету</Link>
                  </div>
              </div>
              <div className="col-2">
                  <img className="head" src={head} alt=""/>
              </div>
          </div>
      </div>
  );
}

export default Header;
